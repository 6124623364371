import React, { useState } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import HelmetComponent from '../components/helmetcomponent';
import NotifModal from '../components/notifmodal';

const pageUrls = require('../constants')

export default function Template({ data }) {
  const [notifModalShow, setNotifModalShow] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();
    setNotifModalShow(true);
  };

  const {
    description,
    navTab1Text1,
    navTab1Link1,
    navTab2Text2,
    navTab4Text4,
    navTabDesc1,
    navTabDesc2,
    navTabText1,
    navTabText2,
    title
  } = data.allContentfulPageMakeDonations.edges[0].node;

  return (
    <Layout nav="colored-nav">
      <HelmetComponent title={title} description={description} />
      <section id="donations-hero">
        <div id="page-sub-header" className="white-space">
          <div className="container">
            <div className="page-sub-header-content">
              <h1 className="black">{title}</h1>
              <p className="gray">{description}</p>
            </div>
          </div>
        </div>
      </section>

      <section id="primary" className="content-area mtn60">
        <main id="main" className="site-main" role="main">
          <section id="section-donations" className="two-columns">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    <li className="nav-item">
                      <a
                        className="nav-link pill-1 active"
                        id="pills-home-tab"
                        data-toggle="pill"
                        href="#pills-home"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true"
                      >
                        {navTabText1}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link pill-2"
                        id="pills-profile-tab"
                        data-toggle="pill"
                        href="#pills-profile"
                        role="tab"
                        aria-controls="pills-profile"
                        aria-selected="false"
                      >
                        {navTabText2}
                      </a>
                    </li>
                  </ul>

                  <div className="tab-content" id="pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="pills-home"
                      role="tabpanel"
                      aria-labelledby="pills-home-tab"
                    >
                      <div className="col-md-6 mt40 mb40">
                        <p>{navTabDesc1}</p>
                      </div>
                      <div className="donate-buttons row">
                        <div className="col-md-4 mt40">
                          <a
                            href={navTab1Link1}
                            data-formid="2940"
                            className="btn-reg btn-purple mom-donate-btn"
                          >
                            {navTab1Text1}
                          </a>
                        </div>
                        <div className="col-md-4 mt40">
                          <a
                            className="pin-payment-button btn-reg btn-purple"
                            href={pageUrls.PIN_PAYMENT_SPONSOR_A_BABY_URL}
                          >
                            {navTab2Text2}
                          </a>
                        </div>
                      </div>
                      <div className="donate-buttons row">
                        <div className="col-md-4 mt40">
                          <a
                            href={pageUrls.PIN_PAYMENT_DONATE_TO_GLORY_REBORN}
                            className="pin-payment-button btn-reg btn-purple"
                          >
                            {navTab4Text4}
                          </a>
                        </div>
                      </div>
                    </div>

                    <div
                      className="tab-pane fade"
                      id="pills-profile"
                      role="tabpanel"
                      aria-labelledby="pills-profile-tab"
                    >
                      <div className="col-md-7 mt40 mb100">
                        <p>{navTabDesc2}</p>
                      </div>
                      <form
                        method="post"
                        onSubmit={handleSubmit}
                        netlify-honeypot="bot-field"
                        data-netlify="true"
                      >
                        <input type="hidden" name="bot-field" />
                        <div className="row">
                          <div className="col-md-4">
                            <input
                              className="form-control"
                              type="text"
                              name="First Name"
                              placeholder="First Name"
                            />
                            <input className="yummyhoney" type="text" placeholder="First Name" />
                          </div>
                          <div className="col-md-4">
                            <input
                              className="form-control"
                              type="text"
                              name="Last Name"
                              placeholder="Last Name"
                            />
                            <input className="yummyhoney" type="text" placeholder="Last Name" />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-8">
                            <input
                              className="form-control"
                              type="text"
                              name="Email"
                              placeholder="Your Email"
                            />
                            <input className="yummyhoney" type="text" placeholder="Your Email" />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-8">
                            <input
                              className="form-control"
                              type="text"
                              name="Contact No."
                              placeholder="Contact No."
                            />
                            <input className="yummyhoney" type="text" placeholder="Contact No." />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-8">
                            <textarea
                              className="form-control"
                              type="text-area"
                              name="Item"
                              placeholder="Description of item (include quantity)"
                            ></textarea>
                            <textarea
                              className="yummyhoney"
                              type="text-area"
                              placeholder="Description of item (include quantity)"
                            ></textarea>
                          </div>
                        </div>
                        <div className="row ml0">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="inlineRadioOptions"
                              id="inlineRadio1"
                              value="Drop-off"
                            />
                            <input
                              className="yummyhoney"
                              type="radio"
                              name="inlineRadioOptions"
                              value="Drop-off"
                            />
                            <label className="form-check-label" htmlFor="inlineRadio1">
                              Drop-off
                            </label>
                            <label className="yummyhoney" htmlFor="inlineRadio1">
                              Drop-off
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="inlineRadioOptions"
                              id="inlineRadio2"
                              value="Pick-up"
                            />
                            <input
                              className="yummyhoney"
                              type="radio"
                              name="inlineRadioOptions"
                              value="Pick-up"
                            />
                            <label className="form-check-label" htmlFor="inlineRadio2">
                              Pick-up
                            </label>
                            <label className="yummyhoney" htmlFor="inlineRadio2">
                              Pick-up
                            </label>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-8">
                            <input
                              className="form-control"
                              type="text"
                              name="Address"
                              placeholder="Address"
                            />
                            <input className="yummyhoney" type="text" placeholder="Address" />
                          </div>
                        </div>
                        <div className="row mt60">
                          <div className="col-md-4">
                            <button type="submit" className="btn-reg btn-purple">
                              Donate Now
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </section>

      <NotifModal
        id={'donateModal'}
        modalShow={notifModalShow}
        onHide={() => setNotifModalShow(false)}
        title={'Thank you!'}
        body={'Your form submission has been received.'}
      />
    </Layout>
  );
}

export const makeDonationsAuPageQuery = graphql`
  query MakeDonationsAuPage($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
        description
      }
    }
    allContentfulPageMakeDonations {
      edges {
        node {
          title
          description
          navTabText1
          navTabText2
          navTab1Text1
          navTab1Link1
          navTab2Text2
          navTab2Link2
          navTab3Text3
          navTab3Link3
          navTab4Text4
          navTab4Link4
          navTabDesc1
          navTabDesc2
          bankDetailsHeading
          country1Name
          country1ContactInfo
          country2Name
          country2ContactInfo
        }
      }
    }
  }
`;
